#communitiesBlock {
  .tabData {
    &__facebookPlaceholder {
      height: 290px;
      padding: 90px 13px 13px 13px;
      box-sizing: border-box;
      box-shadow: 0 0 0 1px rgba(4,47,89,.12) inset;
      border-radius: 3px;
      text-align: center;
    }
  }
}
