#recentGallery {
  .tabData {
    img {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid $color_celeste_approx;
      vertical-align: bottom;
      &:hover {
        border-color: $commonBlue;
      }
    }
  }
}
