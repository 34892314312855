// Due to line-height 18px for description it goes 4px lower than expected, so we'll compensate it...
$firstLineCompensation: 4px;
.branchInfo {
  position: relative;
  &__chief {
    float: left;
    width: 125px;
    margin: 0 10px 0 0;
    font-variant: small-caps;
    text-align: center;
    img {
      width: 125px;
      box-sizing: border-box;
      border: 1px solid $middleGrayColor;
    }
  }
  &__description {
    margin-top: -$firstLineCompensation;
    hyphens: auto;
    text-align: justify;
  }
  .content {
    overflow: hidden;
  }
  &__loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
  }
  .loader {
    &__spinner {
      top: 35%;
    }
    &__message {
      position: absolute;
      top: calc(35% + 60px);
      left: 0;
      width: 100%;
      text-align: center;
      text-shadow: 1px 1px 1px #ddd;
    }
  }
}
