.topMenu {
  position: relative;
  height: 45px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #d1d1d1;
  //background: url($themeImages + 'common/topMenu.png') repeat-x left center;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,e6e6e6+100 */
  background: rgb(252,252,252); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(252,252,252) 0%, rgb(230,230,230) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(252,252,252) 0%,rgb(230,230,230) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(252,252,252) 0%,rgb(230,230,230) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#e6e6e6',GradientType=0 ); /* IE6-9 */
  font-size: 14px;

  &__list {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    text-align: center;
    li {
      position: relative;
      padding: 5px;
      border-bottom: 1px dotted $white;
      list-style: none;
      &:hover > a, &:active > a {
        color: $white;
        background: $linksTileBlue;
      }
      &:hover > ul, &:active > ul {
        display: block;
      }
      &:last-of-type {
        border: none;
      }
    }
    a {
      display: block;
      position: relative;
      padding: 0 10px;
      height: 35px;
      line-height: 35px;
      text-decoration: none;
      color: #333;
      i {
        display: none;
      }
    }
    ul {
      display: none;
      position: absolute;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,e6e6e6+100 */
      background: rgb(252,252,252); /* Old browsers */
      background: -moz-linear-gradient(top, rgb(252,252,252) 0%, rgb(230,230,230) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgb(252,252,252) 0%,rgb(230,230,230) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgb(252,252,252) 0%,rgb(230,230,230) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#e6e6e6',GradientType=0 ); /* IE6-9 */
      border: 1px solid #dddddd;
      z-index: 100;
      width: 250px;
      text-align: left;
    }
    & > li {
      display: inline-block;
      padding: 5px 10px;
      border: none;
      & > ul {
        top: 44px;
        & > li {
          & > ul {
            top: 0;
            left: 200px;
          }
        }
      }
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 37px;
    padding: 0 15px;
    margin: 5px;
    border: 0 none;
    background: none;
    outline: none;
    &:hover, &:active {
      color: $white;
      background: $linksTileBlue;
    }
  }
}

@media only screen and (max-width: 955px) {
  .topMenu {
    &__list {
      & > li {
        padding: 5px 5px;
      }
    }
  }
}

@media only screen and (max-width: 875px) {
  .topMenu {
    &__list {
      li {
        a {
          padding: 0 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 795px) {
  .topMenu {
    &__list {
      & > li {
        padding: 5px 0;
      }
    }
  }
}

@media only screen and (max-width: 715px) {
  .topMenu {
    &__list {
      li {
        a {
          padding: 0 2px;
        }
      }
    }
  }
}

@media only screen and (max-width: 665px) {
  .topMenu {
    &__list {
      display: none;
      position: absolute;
      top: 47px;
      left: 0;
      width: 100%;
      background: #f5f5f5;
      z-index: 1;
      &--expanded {
        display: block;
      }
      li {
        padding: 5px 0;
        a {
          padding: 0 10px;
        }
        &:hover ul, &:active ul {
          display: none;
        }
        &.topMenu__item--expanded {
          height: auto;
          & > a {
            & > i {
              &:first-of-type {
                display: none;
              }
              &:last-of-type {
                display: block;
              }
            }
          }
          & > ul {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            border-left: none;
            border-right: none;
          }
        }
      }
      i {
        display: none;
        position: absolute;
        top: 0;
        right: 5px;
        width: 30px;
        height: 30px;
        font-size: 20px;
        font-style: normal;
        text-align: center;
        line-height: 37px;
        &:first-of-type {
          display: block;
        }
      }
      & > li {
        display: block;
        text-align: left;
        border-bottom: 1px dotted $white;
        & > ul {
          background: #eeeeee;
          & > li {
            & > ul {
              background: #dddddd;
            }
          }
        }
      }
    }
    &__dropdown {
      display: block;
    }
  }
}
