/*Социалки начало*/
.contentInfo {
  .social {
    margin-right: 15px;
    font-size: 12px;
    height: 32px;
    line-height: 31px;
    float: left;
    &.clear {
      height: 0;
    }
    a {
      display: block;
      height: 32px;
      text-decoration: none;
      i {
        font-size: 16px;
        vertical-align: text-bottom;
        margin-right: 3px;
        // Order matters, lol :)
        &:last-child {
          display: none;
        }
        &:first-child {
          display: initial;
        }
        // Order matters, lol :)
      }
      &:hover {
        color: $linksTileBlue;
        i {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: initial;
          }
        }
      }
    }
    //&.likes_count {
    //  a {
    //    background-image: url('/images/raw/like.png');
    //  }
    //}
    //&.category {
    //  text-transform: capitalize;
    //  a {
    //    background-image: url('/images/raw/folder.png');
    //  }
    //}
    &.stateless {
      a {
        text-decoration: none;
        cursor: default;
        color: $black;
        i {
          color: $commonBlue;
          &:first-child {
            display: initial;
          }
          &:last-child {
            display: initial;
          }
        }
      }
    }
    &.pub_date {
      float: right;
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .contentInfo {
    width: 100%;
    float: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .social {
      float: none;
      margin: 0;
      &.pub_date {
        float: none;
        width: 100%;
        text-align: right;
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .contentInfo {
    float: right !important;
    width: 65%;
    margin: 10px 0 0 0;
    .social {
      &.pub_date {
        width: auto;
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .contentInfo {
    width: auto;
    .social {
      margin: 0 10px 0 0;
    }
  }
}
