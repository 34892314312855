.hotNews {
  max-width: 1000px;
  min-height: 380px;
  box-sizing: border-box;
  padding: 4px;
  margin: 30px 0;
  position: relative;
  border: 1px solid #ddd;

  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  &__image {
    width: 100%;
    height: auto;
  }
  &__video {
    position: absolute;
    right: 0;
  }
  &__text {
    width: 370px;
    height: 370px;
    position: absolute;
    left: 4px;
    top: 4px;
    background: rgba(0, 0, 0, 0.75);
    padding: 0;
    color: #fff;
    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 30px;
      height: 50px;
      line-height: 50px;
      margin-top: 8px;
    }
    .title {
      font-size: 60px;
      font-family: "Arial Black";
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      line-height: 55px;
      text-align: center;
      b {
        font-size: 48px;
        line-height: 48px;
      }
      span {
        font-size: 110px;
        line-height: 80px;
      }
    }
    .short-description {
      font-size: 14px;
      color: #fff;
      width: 315px;
      margin: 10px auto 14px auto;
      line-height: 14px;
    }
    .more {
      position: absolute;
      bottom: 23px;
      left: 24px;
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .hotNews {
    margin: 0;
    width: 100%;
    height: auto;
    min-height: auto;
    &__video {
      display: none;
    }
    &__text {
      padding: 10px 10px 50px 10px;
      box-sizing: border-box;
      position: static;
      width: 100%;
      height: 100%;
      h1 {
        font-size: 28px;
      }
      .title {
        word-break: break-word;
      }
      .short-description {
        width: 100%;
        text-align: justify;
      }
      .more {
        left: 14px;
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMaxWidth) {
  .hotNews {
    min-height: 380px;
    margin: 30px 0;
    &__video {
      display: block;
    }
    &__text {
      width: 370px;
      height: 370px;
      position: absolute;
      padding: 0;
      h1 {
        font-size: 30px;
      }
      .short-description {
        width: 315px;
      }
      .more {
        left: 24px;
      }
    }
  }
}
