.textPageTable {
  border: 1px solid #b1c2dc;
  border-collapse: separate;
  margin: 15px 0;
  font-size: 12px;
  background: #fff;
  text-indent: 0;
  width: 100%;
  caption {
    font-size: 16px;
    line-height: 150%;
    margin: 10px 0;
    font-weight: bold;
    text-align: left;
    text-indent: 30px;
  }
  tr {
    &:hover {
      background: #f3f8ff;
    }
    &:first-child {
      background: none;
      th {
        border-top: none;
      }
    }
    th {
      border-top: 1px solid #b1c2dc;
      border-bottom: 1px solid #b1c2dc;
      background: #F1F4F9;
      padding: 7px 5px;
      text-align: center;
      &.leftBorderedHeading {
        border-left: 1px solid #b1c2dc;
      }
      &.rightBorderedHeading {
        border-right: 1px solid #b1c2dc;
      }
    }
    td {
      border-bottom: 1px solid #fff;
      border-top: 1px solid #fff;
      text-align: center;
      padding: 0 5px;
      &:first-child {
        background: #F1F4F9;
        border-left: none;
        border-right: 1px solid $white;
        padding: 7px 0 7px 10px;
        width: 230px;
        text-align: left;
      }
      &:nth-child(2) {
        border-left: 1px solid #dce4ef;;
      }
      &.empty {
        text-align: center;
        padding: 0 5px;
        background: none;
        width: auto;
      }
    }
    &.separator {
      td {
        background: #dce4ef;
        height: 1px;
        padding: 0;
        border: none;
      }
    }
    &.highlighted {
      td {
        background: rgba(117, 204, 51, 0.2);
      }
    }
  }
  &--admin {
    thead {
      tr {
        th {
          &:first-child {
            width: 20px;
            padding: 7px 0 7px 10px;
            text-align: left;
            input {
              margin: 0;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            width: 20px;
            input {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
