//colors
$black: #000000;
$white: #ffffff;
$commonBlue: #0099cc;
$linksTileBlue: #4caef2;
$middleGrayColor: #c3c3c3;
$lightGrayColor: #f1f1f1;
$color_celeste_approx: #cccccc;
$color_suva_gray_approx: #888;
$color_alto_approx: #dddddd;
$salmon: #FA8072;
$commonOrange: #ff9031;

$themePath: '/themes/2016/';
$themeImages: '#{$themePath}images/';
$fontsPath: '/fonts/';
$fontAwesomePath: '/fonts/font-awesome/';

$mobilePortraitMinWidth: 320px;
$mobileLandscapeMinWidth: 568px;
$mobilePortraitMaxWidth: 414px;
$mobileLandscapeMaxWidth: 823px;
$tabletPortraitMinWidth: 768px;
$tabletPortraitMaxWidth: 1024px;

* {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
}

body {
  font-family: "Segoe UI", "Ubuntu", "Verdana";
  font-size: 14px;
  line-height: 18px;
  background: url('#{$themeImages}common/bodyTile.png') no-repeat right top;
  width: 100%;
}

img {
  border: none;
}

a {
  color: $commonBlue;
  text-decoration: underline;
  outline: none;
  &:hover,&:focus {
    text-decoration: none;
  }
  &.white {
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
  &.blueButton {
    font-size: 12px;
    line-height: 15px;
    color: $white;
    background: $commonBlue;
    display: inline-block;
    padding: 5px 8px 7px;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
  float: none;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}

.required {
  color: #f00;
}

@mixin border-radius($cornerRadiusList...) {
  -webkit-border-radius: $cornerRadiusList;
  -moz-border-radius: $cornerRadiusList;
  -ms-border-radius: $cornerRadiusList;
  border-radius: $cornerRadiusList;
}

.roundedCorners1 {
  @include border-radius(1px);
}

.roundedCorners2 {
  @include border-radius(2px);
}

.roundedCorners3 {
  @include border-radius(3px);
}

.roundedCorners4 {
  @include border-radius(4px);
}

.roundedCorners5 {
  @include border-radius(5px);
}

.lightGrayTiled {
  background: #f5f5f5;
}

.lightGrayTiled.withHover:hover {
  background: #f0f6ff;
}

.lightGrayTiledWithTransparency {
  background-color: rgba(245, 245, 245, 0.7);
}

dl {
  text-align: left;
  dt {
    font-style: italic;
    font-weight: bold;
  }
  dd {
    padding-left: 30px;
  }
}

.loaderBlock {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  p {
    color: $black;
    i {
      color: $commonBlue;
    }
  }
}

.backToTop {
  display: none;
  position: fixed;
  right: 50px;
  bottom: 50px;
  a {
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border: 1px solid $commonBlue;
    border-radius: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 37px;
    outline: none;
    box-shadow: 1px 1px 2px $middleGrayColor;
    text-shadow: 1px 1px 2px $middleGrayColor;
    background: $white;
    &:hover {
      background: transparent;
    }
    &:active {
      background: $commonBlue;
      color: $white;
      text-shadow: none;
    }
  }
}
