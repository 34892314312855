#administerPhotosUpload {
  form {
    .input {
      &:first-of-type {
        margin-top: 0;
      }
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
      label {
        width: 140px;
        text-align: right;
        margin-right: 10px;
      }
      input, textarea, select {
        width: 500px;
        vertical-align: middle;
        &[type="submit"] {
          width: 250px;
        }
        resize: vertical;
      }
    }
    fieldset {
      padding: 10px 20px 20px 20px;
      border: 1px solid $color_suva_gray_approx;
      border-radius: 2px;
      legend {
        padding: 0 10px;
      }
      input {
        width: 100%;
        margin: 10px 0 0 0;
      }
      textarea {
        width: 100%;
        resize: none;
        height: 100px;
        margin: 10px 0;
      }
      .photosList {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        &__item {
          width: 30%;
          .item {
            &__preview {
              width: 100%;
              height: 200px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}