#administerVideosUpload {
  form {
    .input {
      &:first-of-type {
        margin-top: 0;
      }
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
      label {
        width: 140px;
        text-align: right;
        margin-right: 10px;
      }
      input, textarea, select {
        width: 500px;
        vertical-align: middle;
        &[type="submit"] {
          width: 250px;
        }
        resize: vertical;
      }
      &.image {
        img {
          vertical-align: middle;
        }
      }
      &.video {
        iframe {
          vertical-align: middle;
          width: 500px;
          height: 281px;
        }
      }
    }
  }
}