@media print {
  body {
    header {
      .companyBrand {
        margin-left: (1000px - 370px) / 2;
      }
      #userBlock {
        display: none;
      }
      #searchBlock {
        display: none;
      }
    }
    nav.topMenu {
      display: none;
    }
    section#middle {
      margin-top: 0;
      #blockQuote {
        display: none;
      }
      .lightGrayTiledWithTransparency {
        background: none;
      }
    }
    footer {
      display: none;
    }
    .backToTop {
      display: none !important;
    }
  }
}
