.indexWkb-certification-standards {
  .middleContainer {
    overflow: visible;
  }
}
#indexWKBCertificationStandards {
  .textPageTable {
    table-layout: fixed;
    position: relative;
    tr {
      th {
        position: sticky;
        top: 0;
        border-left: 1px solid #dce4ef;
        width: 9%;
        z-index: 1;
        &:first-child {
          width: 10%;
          border-left: none;
        }
      }
      td {
        border-left: 1px solid #dce4ef;
        &:first-child {
          width: 10%;
          border-left: none;
          padding-right: 10px;
          position: sticky;
          top: -50px;
        }
        ul {
          text-align: left;
          list-style: none;
          li {
            position: relative;
            padding-left: 10px;
            &:before {
              content: "•";
              display: block;
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }
  }
}
