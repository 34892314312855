.flashMessageWrapper {
  position: absolute;
  top: 212px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  .flashMessage {
    border: 1px solid #e0e0e0;
    @include border-radius(3px);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: inline-block;
    padding: 10px 20px;
    background: #f6f7fb;
    box-sizing: border-box;
    color: #545456;
    &.success {
      background: #e6f9e7;
      border-color: #CCDFCC;
      color: #4F694F;
    }
    &.errors {
      background: #f6d3cf;
      border-color: #faa9a6;
      color: #fb3431;
    }
    p {
      line-height: 24px;
      i.fa {
        font-size: 24px;
        vertical-align: bottom;
        margin-right: 5px;
      }
    }
    ul {
      margin: 10px auto;
      list-style: inside;
      text-align: left;
      width: 350px;
    }
  }
}