@font-face {
  font-family: 'Arial Black';
  src: url('/fonts/ariblk.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/fonts/segoeui.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/fonts/segoeuib.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome-webfont.eot?v=4.2.0');
  src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff?v=4.2.0') format('woff'), url('/fonts/fontawesome-webfont.ttf?v=4.2.0') format('truetype'), url('/fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Snow Kei 2';
  src: url('/fonts/snow-kei-2.ttf');
  font-weight: bold;
  font-style: normal;
}
