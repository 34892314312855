.homeRecents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;

  .homeBottomBlock {
    padding: 20px 20px 47px 20px;
    width: 31.5%;
    border: 1px solid #ddd;
    box-sizing: border-box;
    margin: 10px 0;
    position: relative;
    h2 {
      font-size: 24px;
      line-height: 28px;
      color: $commonBlue;
      font-weight: bold;
      text-transform: uppercase;
    }
    & > .content {
      .item {
        margin: 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px dotted #ccc;
        display: flex;
        .time {
          font-size: 12px;
          color: #999;
          text-transform: uppercase;
          text-align: right;
          width: 30px;
          line-height: 18px;
          .header {
            font-size: 18px;
            font-weight: bold;
            margin-top: -2px;
          }
          .content {
            margin-top: 3px;
          }
        }
        .data {
          font-size: 14px;
          color: #666;
          width: 230px;
          margin-left: 5px;
          line-height: 18px;
          .header {
            color: #333;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            a {
              color: $black;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .content {
            margin-top: 1px;
          }
        }
      }
      & > :last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    .footer {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
  &__gallery {
    .content {
      text-align: center;
      padding: 36px 0;
      a {
        display: block;
        img {
          width: 260px;
          height: 155px;
          vertical-align: bottom;
        }
      }
    }
  }

  &__announcements {
    .content {
      .item {
        .time {
          & > * {
            display: inline-block;
          }
        }
        .time, .data {
          text-align: left !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .homeRecents {
    .homeBottomBlock {
      height: auto;
      width: 100%;
      margin: 20px 0 0 0;

      .content {
        .item {
          justify-content: space-between;
          align-items: center;
          .time, .data {
            float: none;
            margin: 0;
            width: auto;
          }
          .time {
            flex: 0 0 30px;
          }
          .data {
            width: 85%;
          }
        }
      }
    }
    &__gallery {
      .content {
        a {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .homeRecents {
    .homeBottomBlock {
      width: 48.5%;
      &.homeRecents {
        &__news {
          width: 100%;
          .content {
            .item {
              .data {
                width: 91%;
              }
            }
          }
        }
        &__gallery {
          .content {
            padding: 15px 0;
            a {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMaxWidth) {
  .homeRecents {
    flex-wrap: nowrap;
    .homeBottomBlock {
      width: 31.5%;
      margin: 0;
      &.homeRecents {
        &__news {
          width: 31.5%;

          .content {
            .item {
              .data {
                width: 235px;
              }
            }
          }
        }
        &__gallery {
          .content {
            padding: 36px 0;
          }
        }
        &__announcements {
          .content {
            .item {
              flex-wrap: wrap;
              .time {
                flex: auto;
              }
            }
          }
        }
      }
    }
  }
}
