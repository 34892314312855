#newsAdd {
  form {
    .input {
      &:first-of-type {
        margin-top: 0;
      }
      &.preview {
        display: flex;
        .data {
          margin-left: 3px;
          .image-upload {
            small {
              display: block;
            }
          }
        }
      }
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
      label {
        width: 140px;
        text-align: right;
        margin-right: 10px;
      }
      input, textarea, select {
        width: 500px;
        vertical-align: middle;
        &[type="submit"] {
          width: 250px;
        }
        resize: vertical;
      }
      &.textarea {
        textarea[name="form[News][article]"] {
          display: none;
        }
        .ql {
          &-toolbar {
            width: 632px;
            margin: 0 auto;
            .ql-formats {
              margin-right: 10px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
          &-container {
            height: 300px;
            width: 632px;
            margin: 0 auto;
          }
        }
      }
    }
    fieldset {
      position: relative;
      padding: 10px;
      label {
        position: absolute;
        top: -13px;
        left: 10px;
        background: $white;
        padding: 0 10px;
      }
      .addLinkInput {
        display: flex;
        justify-content: space-between;
        input {
          width: 45%;
        }
        .removeLink {
          line-height: 31px;
          font-size: 20px;
          &:hover {
            color: $linksTileBlue;
          }
        }
      }
      .addLink {
        display: block;
        width: 200px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        line-height: 24px;
        margin: 20px auto;
        font-weight: bold;
        &:hover {
          background: $linksTileBlue;
        }
      }
    }
  }
}
