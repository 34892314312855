.overlay {
  padding: 10px 15px 15px 15px;
  &__header {
    margin: 0 0 15px 0;
    border-bottom: 1px solid $middleGrayColor;
    padding-bottom: 10px;
    h1 {
      font-size: 30px;
      line-height: 30px;
      font-weight: normal;
      color: $commonBlue;
    }
  }
  &__content {
    //.overlay .input {margin: 10px 0; position: relative;}
    //.overlay .input .inFieldLabel {position: absolute; color: #888; font-size: 20px; left: 12px; bottom: 6px;}
    //.overlay .input input {border-radius: 2px; font-size: 20px; color: #888; padding: 5px 10px; font-family: "Segoe UI", "Ubuntu", "Verdana";}
    //.overlay .text input {width: 278px;}
    //.overlay .password input {width: 278px;}
    //.overlay .options {font-size: 12px; text-align: right;}
    //.overlay .submit {overflow: hidden; border-top: 1px dotted #c3c3c3; padding-top: 10px;}
    //.overlay .submit input {padding: 1px 15px 6px 15px;}
    //.overlay .submit .colorbox-close {float: right; color: #888; font-size: 14px; line-height: 36px;}
  }
}
