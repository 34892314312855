$headerHeight: 165px;
$logoHeight: 128px;
$logoVMargins: ($headerHeight - $logoHeight) / 2;
$nameHeight: 65px;
.mainHeader {
  max-width: 1000px;
  margin: auto;
  height: $headerHeight;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: block;
  }
  .companyBrand {
    width: 368px;
    height: 100%;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $black;
    outline: none;
    overflow: hidden;
    &__countryFlag {
      width: 300px;
      position: absolute;
      z-index: -1;
      margin-top: -35px;
      margin-left: 30px;
    }
    &__logo {
      float: left;
      width: 36px;
      height: $logoHeight;
      margin: $logoVMargins 40px $logoVMargins 0;
    }
    &__name {
      display: block;
      float: right;
      margin: 55px 0 45px 0;
      .name__top {
        display: block;
        font-size: 11px;
        line-height: 15px;
      }
      .name__middle {
        display: block;
        font-size: 36px;
        line-height: 40px;
        margin: -9px 0 0 -2px;
      }
      .name__bottom {
        display: block;
        font-size: 24px;
        line-height: 28px;
        text-align: right;
        margin: -9px 2px 0 0;
        text-transform: none;
        span {
          text-transform: uppercase;
        }
      }
    }
  }

  &__right {
    margin: 60px 0 50px 0;

    .userBlock {
      font-size: 14px;
      vertical-align: middle;
      margin-bottom: 6px;
      ul {
        list-style: none;
        &:after {
          display: block;
          content: "";
          clear: both;
        }
      }
      &--logged {
        ul {
          li {
            border-left: 1px solid $middleGrayColor;
            padding: 0 4px 0 9px;
            &.messages {
              background: url('/images/common/messages.png') no-repeat 6px center;
              padding-left: 25px;
            }
            &.tools {
              background: url('/images/common/tools.png') no-repeat 6px center;
              padding-left: 25px;
            }
            &.add-news {
              background: url('/images/common/add-news.png') no-repeat 6px center;
              padding-left: 25px;
            }
            &.administer {
              background: url('/images/common/administer.png') no-repeat 6px center;
              padding-left: 25px;
            }
            &:first-child {
              border-left: none;
              background: url('#{$themeImages}common/home.png') no-repeat left 1px;
              padding-left: 20px;
              background-size: 15px;
            }
            &:last-child {
              padding: 0 0 0 29px;
              border-left: 1px solid $middleGrayColor;
              background: url('#{$themeImages}common/logout.png') no-repeat 10px center;
              background-size: 17px;
            }
            a {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .searchBlock {
      background: $white;
      position: relative;

      &--logged {
        input[type="submit"] {
          width: 27px;
          height: 27px;
        }
      }
      input[type="text"] {
        width: 100%;
        background: url('#{$themeImages}common/search.png') no-repeat right 10px top 6px;
        background-size: 17px;
      }
      input[type="submit"] {
        background: none;
        border: 0 none;
        position: absolute;
        width: 33px;
        height: 31px;
        right: 0;
        bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .mainHeader {
    width: 95%;
    margin: 0 auto;
    &__left {
      &.companyBrand {
        .companyBrand {
          &__logo {
            margin: 18.5px 0;
          }
          &__name {
            .name {
              &__middle {
                font-size: 32px;
              }
            }
          }
        }
      }
    }
    &__right {
      position: absolute;
      margin: 0;
      z-index: 1;
      top: 170px;
      .userBlock {
        margin: 0;
        ul {
          &:after {
            display: none;
          }
          li {
            float: none;
            height: 37px;
            display: inline-block;
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0;
            }
            a {
              height: 37px;
              line-height: 35px;
            }
            &:hover > a {
              color: $white;
              background: $linksTileBlue;
            }
          }
        }
        &--logged {
          ul {
            li {
              &:first-child {
                background-position: left 5px top 10px;
                padding-left: 25px;
              }
            }
          }
        }
      }
      .searchBlock {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .mainHeader {
    &__left {
      &.companyBrand {
        width: 268px;
      }
    }
    &__right {
      position: static;
      .userBlock {
        ul {
          display: flex;
          justify-content: space-between;
        }
      }
      .searchBlock {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
