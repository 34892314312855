.dojoShow {
  & > .addthis_sharing_toolbox {
    margin-right: 10px;
    float: left;
    width: 160px;
    text-align: center;
    a {
      display: inline-block;
      span {
        vertical-align: bottom;
      }
    }
  }

  & > .right {
    float: right;
    width: 463px;
  }

  &__description {
    overflow: hidden;
    & > p {
      & > i {
        display: none;
        color: $commonBlue;
        width: 15px;
      }
      & > span {
        display: inline-block;
        width: 145px;
        color: #676767;
      }
    }
    & > img {
      width: 160px;
      height: 160px;
      border: 1px solid $middleGrayColor;
      vertical-align: bottom;
      box-sizing: border-box;
      margin: 0 10px 0 0;
      float: left;
    }
  }

  &__schedule {
    margin-top: 10px;
    .textPageTable {
      tr {
        &.rowspan {
          td {
            &:first-child {
              text-align: center;
              padding: 0 5px;
              background: none;
              width: auto;
              border-left: 1px solid #dce4ef;
              border-right: 0;
            }
            &:nth-child(2) {
              border-left: 0;
            }
          }
        }
      }
    }
  }

  &__photos {
    margin-top: 10px;
    ul {
      list-style: none;
      &::after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        clear: both;
      }
      .item {
        float: left;
        padding: 5px 7px;
        border: 1px solid #fafafa;
        margin: 4px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(5n) {
          margin-left: 0;
        }
        &:first-child {
          margin-left: 0;
          margin-right: 4px;
        }
        &:hover {
          border: 1px solid $commonBlue;
        }
        img {
          width: 145px;
        }
      }
    }
  }

  &__coaches {
    h2 {
      i {
        font-weight: normal;
        color: $commonOrange;
        cursor: help;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      justify-content: flex-start;
      li {
        border: 1px solid $middleGrayColor;
        width: 24%;
        padding: 10px;
        margin: 0 1.334% 10px 0;
        box-sizing: border-box;
        text-align: center;
        display: block;
        &:nth-of-type(4n) {
          margin-right: 0;
        }
        &:hover {
          background: rgba(0, 153, 204, 0.03);
        }
        img {
          width: 120px;
          height: 120px;
          border-radius: 60px;
          border: 2px solid $commonBlue;
          box-sizing: border-box;
          margin: 0;
        }
        div {
          padding: 0;
          text-align: center;
          h3 {
            font-weight: normal;
            line-height: 18px !important;
            margin: 0 0 10px 0 !important;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mobilePortraitMaxWidth) {
  .dojoShow {
    &__description {
      & > p {
        & > i {
          display: inline-block;
        }
        & > span {
          display: none;
        }
      }
      & > img {
        width: 150px;
        height: 150px;
      }
    }
    &__coaches {
      ul {
        li {
          width: 100%;
          display: flex;
          margin: 0 0 10px 0;
          img {
            margin-right: 10px;
          }
          div {
            text-align: left;
            padding: 10px 0;
            h3 {
              margin: 0 0 20px 0 !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mobileLandscapeMaxWidth) {
}
