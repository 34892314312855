.contentList {
  .photoItem {
    margin: 0 10px 10px 0;
    padding: 0;
    float: left;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    & > .imageWrapper {
      float: left;
      width: 160px;
      position: relative;
      img {
        width: 160px;
        height: 160px;
        border: 1px solid #ccc;
        vertical-align: bottom;
        box-sizing: border-box;
      }
      & > a {
        display: inline-block;
      }
      & > a.readMoreLabel {
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        text-align: center;
        padding: 2px 5px 5px 5px;
        width: 150px;
        bottom: 0px;
        text-decoration: none;
      }
      ul {
        position: absolute;
        top: 5px;
        left: 5px;
      }
      &:hover {
        & > a.readMoreLabel {
          background-color: rgba(76, 174, 242, 0.7);
        }
      }
    }
  }
  &__item {
    margin: 0 0 15px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ccc;
    &:last-of-type {
      border: 0 none;
      margin: 0;
      padding: 0;
    }
    & > .left {
      margin-right: 10px;
      width: 150px;
      &.imageWrapper {
        position: relative;
        img {
          width: 150px;
          height: 150px;
          border: 1px solid #ccc;
          vertical-align: bottom;
          box-sizing: border-box;
        }
        a {
          display: block;
          position: absolute;
          background-color: rgba(210, 210, 210, 0.7);
          color: #fff;
          text-align: center;
          padding: 2px 5px 5px 5px;
          width: 60px;
          bottom: 5px;
          text-decoration: none;
          &:hover {
            background-color: rgba(76, 174, 242, 0.7);
          }
        }
      }
    }
    & > .right {
      //width: 513px;
    }
    & > .addthis_sharing_toolbox {
      text-align: center;
      a {
        display: inline-block;
        margin: 0 5px 0 0;
        span {
          vertical-align: bottom;
        }
      }
    }
    // Importants here are to override styles from _middle.scss
    h2 {
      position: relative;
      margin: 0 !important;
      font-size: 18px !important;
      line-height: 24px !important;
      text-transform: uppercase;
      text-indent: 34px;
      a {
        display: inherit;
      }
      &:hover a {
        color: $linksTileBlue;
      }
      a:hover {
        text-decoration: none;
      }
    }
    .contentDescription {
      text-align: justify;
      font-size: 14px;
      text-indent: 30px;
      line-height: 20px;
    }
    .action {
      font-size: 12px;
      position: absolute;
      display: inline-block;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 1px;
      width: 25px;
      height: 25px;
      z-index: 1;
      overflow: hidden;
      cursor: pointer;
    }
  }
}

//TODO: move it somewhere
ul.options {
  font-size: 12px;
  position: absolute;
  display: inline-block;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1px;
  width: 25px;
  height: 25px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    width: auto;
    height: auto;
    box-shadow: 3px 3px 4px -2px #ccc;
  }
  li {
    display: block;
    border-bottom: 1px dotted #ccc;
    text-align: left;
    &:last-of-type {
      border-bottom: 0 none;
    }
    a,span {
      padding: 2px 5px 2px 25px;
      display: block;
    }
    .title {
      background: url('/images/common/administer.png') no-repeat 5px center;
    }
    .edit {
      background: url('/images/common/blog.png') no-repeat 5px center;
    }
    .delete {
      background: url('/images/cancel.png') no-repeat 4px center;
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .contentList {
    &__item {
      h2 {
        word-break: break-word;
      }
      & > .right {
        float: none;
      }
      & > .left {
        width: 100px;
        &.imageWrapper {
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .addthis_sharing_toolbox {
        float: none;
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .contentList {
    &__item {
      .addthis_sharing_toolbox {
        float: left;
        width: 35%;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMinWidth) {
  .contentList {
    &__item {
      .addthis_sharing_toolbox {
        float: left;
        width: auto;
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMaxWidth) {
  .contentList {
    &__item {
      & > .left {
        width: 150px;
        &.imageWrapper {
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
