#editWKBDownloadsList {
  form {
    .input {
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
    }
    fieldset {
      position: relative;
      padding: 10px;
      label {
        position: absolute;
        top: -13px;
        left: 10px;
        background: $white;
        padding: 0 10px;
      }
      .addLinkInput {
        display: flex;
        justify-content: space-between;
        input {
          width: 45%;
        }
        .removeLink {
          line-height: 31px;
          font-size: 20px;
          &:hover {
            color: $linksTileBlue;
          }
        }
      }
      .addLink {
        display: block;
        width: 200px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        line-height: 24px;
        margin: 20px auto;
        font-weight: bold;
        &:hover {
          background: $linksTileBlue;
        }
      }
    }
  }
}
