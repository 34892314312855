.block {
  background: #fff;
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #ccc;
  padding: 10px;
  .header {
    margin-bottom: 10px;
    .block-header {
      font-size: 20px;
      line-height: 24px;
      color: $commonBlue;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  .content {
    width: auto;
    padding: 0;
    float: none;
    background: none;
    border: 0 none;
    margin: 0;
    &__empty {
      margin: 50px 0;
      text-align: center;
    }
  }
  .footer {
    margin-top: 15px;
    overflow: hidden;
    &--empty {
      margin: 0;
    }
  }
}

/* Common styles for right sidebar tabbed block */
.tabbedBlock {
  .tabControls {
    font-size: 12px;
    //border-bottom: 2px solid #0089d4;
    border-bottom: 2px solid $commonBlue;
    padding-bottom: 2px;
    margin-bottom: 5px;
    .tabControl {
      display: inline-block;
      padding: 5px 0;
      text-align: center;
      width: 115px;
      background: #f1f1f1;
      margin-right: 3px;
      cursor: pointer;
      a {
        color: $commonBlue;
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.active {
//        background: #0089d4;
        background: $commonBlue;
        text-align: center;
        color: #fff;
        position: relative;
        text-decoration: none;
        a {
          color: $white;
        }
        & > span.small-pointer {
          position: absolute;
          display: block;
          bottom: -9px;
          left: 52px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: $commonBlue transparent transparent transparent;
        }
      }
    }
  }
  .tabData {
    display: none;
    margin-top: 10px;
    position: relative;
    &:first-of-type {
      display: block;
    }
  }
}

.leftSidebarBlock {
  padding: 12px 20px 20px 20px;
  margin-bottom: 20px;
  width: 233px;
}

.rightSidebarBlock {
  padding: 12px 20px 20px 20px;
  margin-bottom: 20px;
  width: 233px;
  &.withFooter {
    padding: 15px 35px;
  }
  .footer {
//    margin: 10px 0 3px 0;
    a {
      font-size: 12px;
      color: #fff;
      background: #4caef2;
      display: inline-block;
      padding: 5px 5px 7px;
    }
  }
}

/* Блок пользователей */
#sidebarUsers {
  .tabData {
    ul {
      overflow: hidden;
      li {
        display: block;
        float: left;
        font-size: 12px;
        text-align: center;
        width: 74px;
        margin: 0 3px 5px 3px;
        &:first-of-type, &:nth-of-type(4) {
          margin-left: 0;
          margin-right: 2px;
          text-align: left;
        }
        &:last-of-type, &:nth-of-type(3) {
          margin-right: 0;
          text-align: right;
        }
        a {
          text-decoration: none;
          color: $middleGrayColor;
          display: inline-block;
          width: 70px;
          text-align: center;
          img {
            height: 70px;
            width: 70px;
            border: 1px solid $middleGrayColor;
            box-sizing: border-box;
            vertical-align: bottom;
          }
        }
        &:hover {
          a {
            color: $commonBlue;
            img {
              border-color: $commonBlue;
            }
          }
        }
      }
      &.recent {
        display: none;
      }
    }
  }
}

#infoBlock {
  .content {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

/*Блок коментариев*/
#commentBlock {
  margin: 20px 0 0 0;
  padding: 0px;
  .header {
    position: relative;
    padding: 4px 10px 5px 10px;
    h2 {
      margin: 0px;
    }
  }
  .content {
    padding: 0px 10px 10px 10px;
    font-size: 14px;
    .tabControls {
      line-height: 100%;
      .tabControl {
        height: 18px;
        line-height: 18px;
      }
    }
    .comment-tab {
      display: none;
    }
    #kyTab {
      display: block;
      #AddComment {
        .avatar {
          float: left;
          text-align: center;
          margin-right: 10px;
          a {
            text-decoration: none;
            color: #888;
            display: inline-block;
            width: 100px;
            img {
              height: 100px;
              width: 100px;
              border: 1px solid $middleGrayColor;
              box-sizing: border-box;
            }
          }
          &:hover {
            a {
              color: $commonBlue;
            }
            img {
              border-color: $commonBlue;
            }
          }
        }
        .textarea {
          margin: 0 0 10px 0;
          label {
            text-align: left;
            padding-left: 10px;
          }
          textarea {
            border: 1px solid $middleGrayColor;
            box-sizing: border-box;
            width: 563px;
            height: 150px;
            vertical-align: bottom;
            resize: vertical;
          }
        }
        .submit {
          overflow: hidden;
          .hint {
            float: left;
            line-height: 29px;
          }
          input[type="submit"] {
            float: right;
            width: 150px;
          }
        }
      }
    }
  }
}

#userTools {
  .content {
    ul {
      list-style: none;
      font-size: 12px;
      li {
        border-bottom: 1px dotted #ccc;
        padding: 7px 0 7px 0;
        &:last-of-type {
          padding-bottom: 0;
          border-bottom: none;
        }
        .counter {
          background: $middleGrayColor;
          padding: 0 4px;
          min-width: 9px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
        &.active {
          a {
            color: $salmon;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@import "../blocks/right/branch-info";
@import "../blocks/right/dojos-map";
@import "../blocks/right/gallery";
@import "../blocks/right/news_top_rated";
@import "../blocks/right/announcements_top_rated";
@import "../blocks/right/communities";

@import "../blocks/left/user-profile";

//#CommentBlock .header .options {position: absolute; right: 10px; top: 3px; font-size: 14px; width: 150px;}
//#CommentBlock .header .options.authorized {width: 200px;}
/*Galleries - 537px, User-post - 492px, Albums-photo - 492px*/

//#commentBlock .content #AddComment .textarea textarea {width: 537px;}
//#publicPost #commentBlock .content #AddComment .textarea textarea {width: 492px;}
//#albumShowContentPublic #commentBlock .content #AddComment .textarea textarea {width: 492px;}
