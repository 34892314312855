.middleSection {
  max-width: 1000px;
  margin: 30px auto 0 auto;
  &.errorError {
    overflow: auto;
  }
  .middleContainer {
    line-height: 150%;
    width: 100%;
    float: left;
    overflow: hidden;
    &__content {
      padding: 25px 5px 0 5px;
      #breadcrumbs {
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          list-style: none;
          line-height: 32px;
          a {
            line-height: 32px;
            display: inline-block;
            min-width: 32px;
            text-align: center;
            vertical-align: bottom;
          }
          &:after {
            content: "\00BB";
            margin: 0 5px 0 3px;
            display: inline-block;
            line-height: 32px;
            vertical-align: 1px;
          }
          &:first-of-type {
            a {
              font-size: 20px;
              margin-left: -10px;
              text-align: right;
            }
          }
          &:last-of-type:after {
            display: none;
          }
        }
      }
    }
    &--withLeftSidebar {
      h1 {
        margin: 7px auto 10px auto;
      }
      .middleContainer__content {
        padding: 0 5px 5px 300px;
      }
    }
    &--withRightSidebar {
      h1 {
        margin: 7px auto 10px auto !important;
      }
      .middleContainer__content {
        padding: 0 300px 5px 5px;
      }
    }
    .textPage {
      padding: 0 30px 30px 30px;
      text-indent: 30px;
      text-align: justify;
      &SideImage {
        &.left {
          margin-right: 15px;
          float: left;
        }
        &.right {
          margin-left: 15px;
          float: right;
        }
      }
      &List {
        padding-left: 30px;
        &.katakanaList {
          list-style: katakana-iroha inside;
        }
      }
    }
    h1 {
      text-transform: uppercase;
      text-indent: 0;
      text-align: center;
      color: $commonBlue;
      font-size: 36px;
      line-height: 40px;
      font-weight: normal;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 20px;
      color: $commonBlue;
      line-height: 150%;
      font-weight: normal;
      margin: 10px 0;
      a {
        text-decoration: none;
      }
    }
    h3 {
      font-size: 16px;
      line-height: 150%;
      margin: 10px 0;
    }
  }
  .leftSidebar {
    float: left;
    margin-left: -995px;
    padding-top: 10px;
  }
  .rightSidebar {
    float: left;
    margin-left: -280px;
    padding-top: 10px;
  }
}

//@media only screen and (max-width: 1015px) {
//  .middleSection {
//    .middleContainer {
//      float: none;
//      &--withRightSidebar {
//        .middleContainer__content {
//          width: 695px;
//          padding-right: 5px;
//        }
//      }
//    }
//    .rightSidebar {
//      float: none;
//      margin-left: 0;
//      padding: 10px 5px;
//      display: flex;
//      box-sizing: border-box;
//      flex-wrap: wrap;
//      justify-content: space-between;
//    }
//  }
//}

//@media only screen and (max-width: 720px) {
//  .middleSection {
//    .middleContainer {
//      &--withRightSidebar, &--withLeftSidebar {
//        .middleContainer__content {
//          width: auto;
//        }
//      }
//      &--withLeftSidebar {
//        .middleContainer__content {
//          padding: 0 5px;
//        }
//      }
//    }
//    .rightSidebar, .leftSidebar {
//      display: none;
//    }
//  }
//}
@media only screen and (max-width: $mobilePortraitMaxWidth) {
  .middleSection {
    .middleContainer {
      h1 {
        font-size: 24px;
        line-height: 30px;
        margin: 7px auto 0 auto !important;
      }
      &__content {
        #breadcrumbs {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .middleSection {
    width: 95%;
    margin: 20px auto;
    .middleContainer {
      float: none;
      .textPage {
        padding: 0 15px 20px 15px;
        text-indent: 15px;
        &List {
          padding-left: 0;
          li {
            border-bottom: 1px dotted $color_celeste_approx;
            padding-bottom: 5px;
            margin-bottom: 5px;
            &:last-of-type {
              border: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
      }
      &--withRightSidebar {
        .middleContainer__content {
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;
        }
      }
    }
    .rightSidebar, .leftSidebar {
      display: none;
    }
  }
}

@media only screen and (min-width: $tabletPortraitMaxWidth) {
  .middleSection {
    margin: 30px auto;
    .middleContainer {
      float: left;
      &--withLeftSidebar {
        .middleContainer__content {
          padding: 0 5px 5px 300px;
        }
      }
      &--withRightSidebar {
        .middleContainer__content {
          padding: 0 300px 5px 5px;
        }
      }
    }
    .rightSidebar, .leftSidebar {
      display: block;
    }
  }
}
