.carousel {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 30px;
  font-size: 0;
  overflow: hidden;
  &__list {
    position: relative;
    list-style: none;
  }
  &__item {
    display: inline-block;
    font-size: 14px;
    margin: 0 4px;
    vertical-align: bottom;
  }
  &__prev, &__next {
    position: absolute;
    top: 0;
    display: block;
    width: 30px;
    height: 100%;
    background: $white;
    color: $commonBlue;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    & > :first-child {
      display: inline-block;
    }
    & > :last-child {
      display: none;
    }
    &:hover {
      color: $linksTileBlue;
      & > :first-child {
        display: none;
      }
      & > :last-child {
        display: inline-block;
      }
    }
    &--disabled {
      color: $color_celeste_approx;
      &:hover {
        color: $color_celeste_approx;
        & > :first-child {
          display: inline-block;
        }
        & > :last-child {
          display: none;
        }
      }
    }
  }
  &__prev {
    left: 0;
  }
  &__next {
    right: 0;
  }
}
