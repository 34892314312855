.commonLoginRegister {
  padding: 25px 0;
  border: 1px solid $color_alto_approx;
  display: flex;
  flex-wrap: wrap-reverse;
  
  &__top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &__block {
      width: 33%;
      padding: 0 40px;
      border-right: 1px dotted $color_alto_approx;
      box-sizing: border-box;
      &:last-of-type {
        border-right: none;
      }

      & > h2 {
        margin: 0 auto 25px auto;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
      }
      &:hover h2 {
        color: $commonBlue;
      }
      form {
        width: 100%;
        margin: 0 auto;
      }
      .input {
        input {
          width: 100%;
          &[type='checkbox'] {
            width: auto;
          }
        }
      }
      .forgot, .checkbox label {
        font-size: 14px;
        display: inline;
      }
    }
  }

  &__mobileHeader {
    display: none;
  }

  &__bottom {
    width: 100%;
    padding: 20px 40px 0 20px;
  }

  &__socials {
    ul {
      text-align: center;
      margin: 15px 25px 0 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        display: inline-block;
        margin: 0 0 15px 0;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        &.disabled {
          position: relative;
          &:after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
          }
        }
        a {
          display: inline-block;
          width: 90px;
          height: 90px;
          background: $color_alto_approx center/contain no-repeat;
          text-decoration: none;
          &.vk {
            background-image: url($themeImages + "users/vk.png");
            &:hover {
            }
          }
          &.fb {
            background-image: url($themeImages + "users/fb.png");
            &:hover {
            }
          }
          &.gp {
            background-image: url($themeImages + "users/gp.png");
            &:hover {
            }
          }
          &.tw {
            background-image: url($themeImages + "users/tw.png");
            &:hover {
            }
          }
        }
      }
    }
    p {
      width: 255px;
      margin: 0 auto;
      font-size: 14px;
      padding-bottom: 20px;
    }
  }
  .bottomPart {
    border-top: 1px dotted $color_alto_approx;
    h1 {
      color: $commonBlue;
      font-size: 36px;
      line-height: 40px;
      font-weight: normal;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }
}
.middleSection.usersCommon-login-register {
  .middleContainer {
    &__content {
      padding-top: 0;
      & > h1 {
        display: none;
      }
    }
  }
}


@media only screen and (max-width: 720px) {
  .usersCommon-login-register {
    .middleContainer {
      background: none;
    }
  }

  .commonLoginRegister {
    margin: 0 20px;
    &__top {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &__block {
        width: 100%;
        padding: 0 20px;
        border-right: none;
        display: none;
        h2 {
          display: none;
        }
        .input {
          input {
            padding: 10px;
          }
        }
        &:nth-child(2) {
          display: block;
        }
      }
      .commonLoginRegister__mobileHeader {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0 20px 10px 20px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 10px;
          position: relative;
          &:after {
            display: block;
            content: "";
            border-right: 2px solid $commonBlue;
            height: 18px;
            position: absolute;
            top: 2px;
            right: -8px;
          }
          &:last-of-type {
            margin-right: 0;
            &:after {
              display: none;
            }
          }
          &.active {
            a {
              text-decoration: none;
              color: $color_suva_gray_approx;
            }
          }
        }
      }
    }

    &__socials {
      ul {
        margin: 15px 55px 0 55px;
        li {
          margin: 0 0 35px 0;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }

    &__bottom {
      display: none;
    }
  }
}
