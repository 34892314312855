.galleryShowVideoGallery {
  & > div {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  &__item {
    float: left;
    padding: 5px 7px;
    border: 1px solid #fafafa;
    margin: 4px;
    &:nth-child(4n) {
      margin-right: 2px;
    }
    &:nth-child(4n+1) {
      margin-left: 2px;
    }
    &:hover {
      border: 1px solid $commonBlue;
    }
  }
  &__preview {
    & > img {
      width: 145px;
      vertical-align: bottom;
    }
  }
}
