.input {
  margin: 15px 0px;
  label {
    box-sizing: border-box;
    padding: 5px 0px 5px 1px;
    outline: none;
    font-size: 14px;
    font-family: "Segoe UI", "Ubuntu", "Verdana";
    display: inline-block;
    margin: 0px;
    i {
      &:hover {
        color: $commonBlue;
      }
    }
  }
  .inputError {
    display: inline-block;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    background: url('/images/common/inputExclamation.png') no-repeat center;
    vertical-align: -7px;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    ul {
      display: none;
      position: absolute;
      border: 2px solid #cc4242;
      width: 350px;
      left: -280px;
      top: 35px;
      background: #fff;
      padding: 5px 5px 5px 30px;
      @include border-radius(3px);
      box-sizing: border-box;
      z-index: 5;
      &:before, &:after {
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 11px 15px;
        content: '';
        position: absolute;
        right: 41px;
      }
      &:before {
        border-color: transparent transparent #cc4242 transparent;
        top: -11px;
      }
      &:after {
        border-color: transparent transparent #ffffff transparent;
        top: -9px;
      }
    }
    &:hover, &:focus {
      ul {
        display: block;
      }
    }
  }
}
input, select, textarea {
  display: inline-block;
  border: 1px solid $color_celeste_approx;
  box-sizing: border-box;
  padding: 5px 7px;
  outline: none;
  font-size: 14px;
  font-family: "Segoe UI", "Ubuntu", "Verdana";
  background-color: $white;
  &:focus {
    border: 1px solid $commonBlue;
  }
  &[type='text'] {}
  &[type='checkbox'] {
    vertical-align: 1px;
    width: auto;
    margin: 0 3px 0 0;
  }
  &[type='submit'] {
    cursor: pointer;
    background: $commonBlue;
    color: $white;
    border: none;
    text-decoration: underline;
    line-height: 36px;
    display: inline-block;
    padding: 0 15px;
    &:focus, &:hover {
      text-decoration: none;
    }
  }
}
select {
  border: 0;
  outline: 1px solid $color_celeste_approx;
  outline-offset: -1px;
  height: 31px;
  &:focus {
    border: 0;
    outline: 1px solid $commonBlue;
  }
}