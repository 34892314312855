#topRatedAnnouncements {
  .tabData {
    ul {
      list-style: none;
      font-size: 12px;
      li {
        border-bottom: 1px dotted $color_celeste_approx;
        padding: 7px 0 7px 5px;
        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }
        a {
          font-size: 12px;
          text-decoration: none;
          &:hover {
            color: $linksTileBlue;
          }
        }
      }
      &.popular {
        display: none;
      }
    }
  }
}
