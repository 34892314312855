.blockQuote {
  padding: 4px;
  text-align: center;
  margin: 0 0 30px 0;
  position: relative;
  border: 1px solid #ddd;

  display: flex;
  flex-direction: column;
  justify-content: center;
  &__image {
    vertical-align: bottom;
    width: 100%;
    height: auto;
  }
  &__text {
    width: 320px;
    height: 190px;
    position: absolute;
    left: 4px;
    top: 4px;
    background: rgba(0, 0, 0, 0.75);
    padding: 0 25px;
    color: #fff;
    text-align: left;
    h3 {
      padding: 25px 0;
      font-weight: normal;
      font-size: 14px;
    }
    span {
      position: absolute;
      bottom: 25px;
      left: 25px;
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .blockQuote {
    display: none;
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .blockQuote {
    display: block;
    &__text {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      padding: 0 10px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.4);
      h3 {
        padding: 10px 0;
        font-size: 12px;
      }
      span {
        position: static;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMinWidth) {
  .blockQuote {
    &__text {
      padding: 0 25px;
      h3 {
        font-size: 14px;
        padding: 25px 0;
      }
      span {
        font-size: 14px;
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
    }
  }
}

@media only screen and (min-width: $tabletPortraitMaxWidth) {
  .blockQuote {
    &__text {
      width: calc(50% - 8px);;
      height: calc(100% - 8px);
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      padding: 0 15px;
      h3 {
        padding: 15px 0;
      }
    }
  }
}
