.mainFooter {
  background: linear-gradient(to bottom, #121b24 0%, #1a2631 100%);

  &__content {
    max-width: 1000px;
    margin: auto;
    background: url('/images/footer-container-tile.png') no-repeat left center;
    overflow: hidden;
  }

  &__copyrights {
    background: url($themeImages + 'common/footer-logo.svg') no-repeat left center;
    min-height: 140px;
    font-size: 12px;
    color: #4d657a;
    display: flex;
    justify-content: center;
    padding-left: 40px;
    box-sizing: border-box;
    a {
      color: #cacaca;
      text-decoration: none;
    }
  }
  &__groups {
    margin: 35px 0 0 0;
    height: 200px;
    .bottom-menu {
      float: left;
      margin-right: 20px;
      list-style: none;
      li a {
        font-size: 12px;
        line-height: 21px;
        color: #cacaca;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .bottom-menu.apps {
      margin: 5px 20px 0 0;
      li {
        margin-bottom: 7px;
        a {
          display: block;
          background: no-repeat 0 0;
          background-size: 35px;
          width: 35px;
          height: 35px;
          &.vk {
            background-image: url($themeImages + 'common/vk.svg');
          }
          &.tw {
            background-image: url($themeImages + 'common/tw.svg');
          }
          &.gp {
            background-image: url($themeImages + 'common/gp.svg');
          }
          &.yt {
            background-image: url($themeImages + 'common/yt.svg');
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .mainFooter {
    &__content {
      width: 95%;
      display: flex;
      flex-wrap: wrap-reverse;
    }
    &__copyrights {
      width: 100%;
      text-align: right;
      flex-direction: column;
      margin: 10px auto 20px auto;
    }
    &__groups {
      float: none;
      margin: 20px auto 10px auto;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      .bottom-menu {
        margin: 0;
        &.apps {
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li {
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $mobileLandscapeMinWidth) {
  .mainFooter {
    &__content {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    &__copyrights, &__groups {
      width: 49%;
      margin: 20px 0;
    }
  }
}

@media only screen and (min-width: $tabletPortraitMinWidth) {
  .mainFooter {
    &__content {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    &__copyrights {
      text-align: left;
      padding-left: 60px;
    }
    &__groups {
      max-width: 300px;
    }
  }
}
