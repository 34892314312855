.pageBranches {
  &__tip {
    margin: 10px 0;
    text-align: center;
  }
  &__mapWrapper {
    position: relative;
  }
  &__tile {
    position: relative;
    top: 0;
    left: 0;
    vertical-align: bottom;
  }
  &__map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;  // Image with map should lie at the very top of z-axis to be hoverable and produce events
    opacity: 0;
    vertical-align: bottom;
  }
  &__citiesWrapper {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    z-index: 1;
  }
  .citiesWrapper {
    &__city {
      position: absolute;
      cursor: pointer;
      font-size: 11px;
      color: $black;
      pointer-events: none; // We need to disable mouse-events for cities to prevent fake-triggers over areas
      &[data-id="1"] {left: 104px; top: 102px;}
      &[data-id="2"] {left: 155px; top: 106px;}
      &[data-id="3"] {left: 208px; top: 127px;}
      &[data-id="4"] {left: 274px; top: 124px;}
      &[data-id="5"] {left: 328px; top: 69px;}
      &[data-id="6"] {left: 412px; top: 88px;}
      &[data-id="7"] {left: 496px; top: 128px;}
      &[data-id="8"] {left: 573px; top: 165px;}
      &[data-id="9"] {left: 535px; top: 207px;}
      &[data-id="10"] {left: 420px; top: 212px;}
      &[data-id="11"] {left: 394px; top: 154px;}
      &[data-id="12"] {left: 330px; top: 179px;}
      &[data-id="13"] {left: 221px; top: 194px;}
      &[data-id="14"] {left: 153px; top: 169px;}
      &[data-id="15"] {left: 109px; top: 182px;}
      &[data-id="16"] {left: 75px; top: 152px;}
      &[data-id="17"] {left: 65px; top: 214px; width: 115px;}
      &[data-id="18"] {left: 35px; top: 228px;}
      &[data-id="19"] {left: 124px; top: 243px;}
      &[data-id="20"] {left: 335px; top: 228px;}
      &[data-id="21"] {left: 472px; top: 254px;}
      &[data-id="22"] {left: 409px; top: 314px;}
      &[data-id="23"] {left: 411px; top: 381px;}
      &[data-id="24"] {left: 341px; top: 286px;}
      &[data-id="25"] {left: 285px; top: 322px;}

      &--active {
        color: $white;
      }
    }
  }
  &__regionsWrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  .regionsWrapper {
    &__region {
      display: none;
      position: absolute;
      width: 667px;
      height: 457px;
      z-index: 0;
      &--active {
        display: block;
      }
    }
  }
}
