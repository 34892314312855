#publicProfileCard {
  .avatar {
    border: 1px solid $middleGrayColor;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 8px 0 20px 0;
    img {
      width: 180px;
      height: 230px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
  p {
    margin-bottom: 5px;
  }
  ul.user-options {
    border-top: 1px dotted #c3c3c3;
    margin-top: 15px;
    padding-top: 10px;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 2px;
      &.send-message a {
        display: block;
        height: 16px;
        width: 16px;
        background: url('/images/common/messages.png') no-repeat center;
      }
      &.follow {
        a {
          display: block;
          height: 16px;
          width: 16px;
          background: url('/images/common/tastemakers.png') no-repeat center;
        }
      }
      &.unfollow {
        a {
          display: block;
          height: 16px;
          width: 16px;
          background: url('/images/common/tastemakers-disabled.png') no-repeat center;
        }
      }
    }
  }
  .additional-info {
    h2 {
      text-align: center;
      border-bottom: 1px dotted #c3c3c3;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    table {
      width: 100%;
      td {
        width: 50%;
        padding: 2px 0;
        &.title {
          font-weight: bold;
        }
      }
    }
    .desc {
      margin-top: 10px;
      position: relative;
      .toggle {
        position: absolute;
        right: 0;
        top: 0;
        a[rel="hide"] {
          display: none;
        }
      }
      .title {
        font-weight: bold;
      }
      .value {
        display: none;
      }
    }
  }
}

