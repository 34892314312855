#administerGalleryEdit {
  form {
    .input {
      &:first-of-type {
        margin-top: 0;
      }
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
      label {
        width: 140px;
        text-align: right;
        margin-right: 10px;
      }
      input, textarea, select {
        width: 500px;
        vertical-align: middle;
        &[type="submit"] {
          width: 250px;
        }
        resize: vertical;
      }
      &.image {
        display: flex;
        align-items: center;
        .group {
          margin-left: 3px;
        }
      }
    }
  }
}
#ChangeThumbnailContainer {
  .header {
    h1 {
      font-weight: normal;
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
      line-height: 1.1;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    .admin-preview-item {
      width: 30%;
      margin: 10px 0;
      a {
        display: block;
        &:hover {
          border: 1px solid $commonBlue;
        }
        img {
          width: 100%;
        }
      }
    }
    form {
      display: none;
    }
  }
  .footer {
    .dialog-pager {
      text-align: center;
      li {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
}