.pagePlans {
  &__yearSelector {
    list-style: none;
    text-indent: 0;
    text-align: left;
    li {
      display: inline-block;
      &:after {
        display: inline-block;
        content: "";
        background: $black;
        width: 1px;
        height: 10px;
      }
      &:first-child, &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        & > a {
          color: $black;
          text-decoration: none;
          pointer-events: none;
        }
      }
    ;
    }
  }
  &__actionsList {
    list-style: none;
    text-indent: 0;
    font-size: 18px;
    li {
      display: inline-block;
      margin: 0 2px;
    }
  }
  .actionsList {

  }
  &__content {
    clear: both;
    .textPageTable {
      tr {
        th {
          background: #F1F4F9;
        }
        td {
          text-align: left;
          padding: 5px 10px;
          &:first-child {
            text-align: center;
          }
        }
        &.separator {
          td {
            padding: 0;
          }
        }
      }
    }
    // In case there is no table with data on page
    .empty {
      padding: 100px 0;
      text-align: center;
    }
  }
  &--list {
    & > ul {
      list-style: none;
      text-align: center;
      & > li {
        font-size: 30px;
        line-height: 2;
      }
    }
  }
}

@media print {
  .pagePlans {
    padding: 0 !important;  //Added to override #middleContainer .textPage styles
    &__yearSelector, &__actionsList {
      display: none;
    }
    &__content {
      .empty {
        padding: 20px 0;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .pagePlans {
    padding: 0 !important;
    &__content {
      .textPageTable {
        tr {
          td {
            &:first-child {
              width: 50px;
            }
          }
        }
      }
    }
  }
}
