.galleryVideoGalleries {
  .contentList__item {
    & > .imageWrapper {
      a {
        width: 75px;
      }
    }
    & > .contentInfo {
      .itemsCount {
        a {
          i {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }
}
