.indexWkb-downloads-list {
  .middleContainer__content {
    & > .options {
      margin-top: 7px;
      &:hover {
        width: 25px;
        height: 25px;
      }
    }
  }
}
#indexWKBDownloadsList {
  & > .wkbLinks {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  & > .empty {
    padding: 100px 0;
    text-align: center;
  }
}
