.downloadableLink {
  display: flex;
  width: 49%;
  border: 1px solid $color_celeste_approx;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  &:hover {
    background: transparentize($linksTileBlue, 0.9);
  }
  .icon {
    font-size: 18px;
    vertical-align: bottom;
    margin-right: 5px;
  }
}
