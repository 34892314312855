.userPublicProfile {
  padding-top: 10px;
  .block {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    .header {
      position: relative;
      h2 {
        margin: 0;
      }
      .options {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .content {
      &--empty {
        position: relative;
        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.6);
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        li {
          a {
            img {
              width: 100%;
              height: auto;
              vertical-align: bottom;
              border: 1px solid $middleGrayColor;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
  &__blog {
    .content {
      .empty {
        text-align: center;
        margin: 50px 0;
      }
    }
  }
  &__photo, &__video {
    .content {
      ul {
        li {
          width: 24%;
        }
      }
    }
  }
  &__tastemakers, &__followers {
    .content {
      ul {
        li {
          width: 15%;
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .usersProfile {
    display: flex;
    flex-wrap: wrap-reverse;
    .leftSidebar {
      display: block;
      float: none;
      margin: 0 5px;
      width: 100%;
      box-sizing: border-box;
      .leftSidebarBlock {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
}
