.textPageTable--galleries {
  tbody {
    tr {
      td {
        &:nth-of-type(5) {
          i {
            border: 1px solid $commonBlue;
            padding: 5px;
            border-radius: 12px;
            color: $commonBlue;
            background: $white;
          }
        }
      }
    }
  }
}