.announcementsShow {
  #middleContainer {
    //    I believe it will be not needed as soon as one more block into content will be added
    min-height: 1086px;
    #middleContent {
      & > .options {margin-top: 9px;}
      & > h1.page-header {text-indent: 20px;}
    }
  }

  .announcementLinks {
    margin-top: 20px;
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {}
  }

  .announcementGallery {
    margin-top: 20px;
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
    }
    &__item {
      img {
        width: 130px;
      }
    }
  }

  .adsBlock {
    margin-top: 20px;
  }
}

#announcementShow {
  & > .addthis_sharing_toolbox {
    margin-right: 10px;
    float: left;
    width: 200px;
    text-align: center;
    a {
      display: inline-block;
      span {
        vertical-align: bottom;
      }
    }
  }

  & > .right {
    float: right;
    width: 463px;
  }

  & > .contentDescription {
    overflow: hidden;
    & > img {
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      vertical-align: bottom;
      box-sizing: border-box;
      margin: 0 10px 0 0;
      float: left;
    }
  }
}
@media only screen and (max-width: $mobilePortraitMaxWidth) {
  .announcementsShow {
    #middleContainer {
      #middleContent {
        & > .options {
          margin-top: 0;
        }

        & > h1.page-header {
          text-indent: 0;
        }
      }
    }
  }
}
