@import "colorbox";

#colorbox {
  //background: $white;
  //box-shadow: 0 0 10px $color_celeste_approx;
  //border: 1px solid $color_celeste_approx;
  //padding: 0;
  //@include border-radius(2px);
}
#cboxContent {
  background: $white;
  box-shadow: 0 0 10px $color_celeste_approx;
  border: 1px solid $color_celeste_approx;
  padding: 0;
  overflow: visible;
  @include border-radius(2px);
}
#cboxTopLeft {
  width: 15px;
  height: 15px;
  background: none;
}
#cboxTopCenter {
  height: 15px;
  background: none;
}
#cboxTopRight {
  width: 15px;
  height: 15px;
  background: none;
}
#cboxBottomLeft {
  width: 15px;
  height: 15px;
  background: none;
}
#cboxBottomCenter {
  height: 15px;
  background: none;
}
#cboxBottomRight {
  width: 15px;
  height: 15px;
  background: none;
}
#cboxMiddleLeft {
  width: 15px;
  background: none;
}
#cboxMiddleRight {
  width: 15px;
  background: none;
}
#cboxClose {
  display: block !important;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: $commonBlue;
  border: 1px solid $white;
  border-radius: 15px;
  color: $white;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  outline: none;
  &:hover {
    text-shadow: 1px 1px 2px $black;
  }
  &:active {
    background: $white;
    color: $commonBlue;
    border-color: $commonBlue;
    text-shadow: none;
  }
}
//TODO: customize further
