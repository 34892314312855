#pageBasicTechnique {
  .kata-table {
    margin-top: 0;
    caption {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  #pageBasicTechnique {
    .requirements {
      tr {
        th {
          // Hides "belt"-column, so no actual images are shown on mobile devices
          &:nth-of-type(2) {
            display: none;
          }
        }
        td {
          // Hides "belt"-column, so no actual images are shown on mobile devices
          &:nth-of-type(2) {
            display: none;
          }
          &:nth-of-type(3) {
            border-left: 1px solid #dce4ef;
          }
        }
      }
    }
  }
}
