.pager {
  text-align: center;
  list-style: none;
  margin: 10px 0;
  li {
    display: inline-block;
    padding: 0 5px;
    &.previous {
      float: left;
      padding: 0;
      text-transform: uppercase;
    }
    &.next {
      float: right;
      padding: 0;
      text-transform: uppercase;
    }
    a {
      color: $commonBlue;
    }
  }
}

.top-pager {
  border-bottom: 1px dotted #ccc;
  font-size: 14px;
  margin: 0 0 15px 0;
  padding: 0 0 10px 0;
}

.bottom-pager {
  border-top: 1px dotted #ccc;
  font-size: 14px;
  margin: 15px 0 0 0;
  padding: 10px 0 0 0;
}