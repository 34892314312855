#userProfile {
  form {
    .input {
      &:first-of-type {
        margin-top: 0;
      }
      &.submit {
        text-align: center;
        margin-bottom: 5px;
      }
      label {
        width: 140px;
        text-align: right;
        margin-right: 10px;
      }
      input, textarea {
        width: 333px;
        vertical-align: middle;
        &[type="submit"] {
          width: 250px;
        }
      }
    }
  }
  .aboutSection {
    & > .left {
      margin-right: 10px;
      float: left;
      width: 180px;
      &.imageWrapper {
        position: relative;
        img {
          width: 180px;
          height: 230px;
          border: 1px solid #ccc;
          vertical-align: bottom;
          box-sizing: border-box;
        }
        a {
          display: block;
          position: absolute;
          background-color: rgba(210, 210, 210, 0.7);
          color: #fff;
          text-align: center;
          padding: 2px 5px 5px 5px;
          width: 60px;
          bottom: 5px;
          text-decoration: none;
          &:hover {
            background-color: rgba(76, 174, 242, 0.7);
          }
        }
      }
    }
    .right {
      float: right;
      width: 483px;
    }
  }
}