.forumIndex {
  .block {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    .header {
      position: relative;
      h2 {
        margin: 0;
      }
      .options {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .content {
      table {
        border-color: gray;
        border-top: 1px solid #b1c2dc;
        border-collapse: separate;
        font-size: 12px;
        tr {
          &:hover {
            background: #f3f8ff;
          }
          th {
            border-bottom: 1px solid #b1c2dc;
            padding: 7px 5px;
            line-height: 16px;
          }
          td {
            border: 1px solid #fff;
            border-left-color: #DCE4EF;
            padding: 2px 5px;
            &.title {
              background: #f1f4f9;
              border-left: 0 none;
              width: 539px;
              h3 {
                font-weight: 400;
                line-height: 18px;
              }
              p {
                font-size: 12px;
                line-height: 18px;
              }
            }
            &.author {
              width: 108px;
            }
            &.center {
              text-align: center;
            }
            &.rounded-left {
              border-bottom-left-radius: 4px;
            }
            &.rounded-right {
              border-bottom-right-radius: 4px;
            }
          }
          &.last-item {
            td {
              border-bottom: 0 none;
            }
          }
          &.separator {
            td {
              background: #dce4ef;
              height: 1px;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
