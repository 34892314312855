.galleryShowPhotoItem {
  margin-bottom: 20px;
  &__fullPhotoWrapper {
    display: block;
    margin-bottom: 10px;
  }
  &__fullPhoto {
    height: 540px;
    width: 100%;
    border: 1px solid $color_celeste_approx;
    box-sizing: border-box;
    object-fit: contain;
    vertical-align: bottom;
  }
  &__carousel {
    .carousel__prev, .carousel__next {
      line-height: 150px;
    }
    .carousel__item {
      & > a {
        & > img {
          width: 145px;
          height: 145px;
          border: 1px solid $color_celeste_approx;
          box-sizing: border-box;
          object-fit: contain;
          vertical-align: bottom;
          opacity: 0.7;
        }
        &:hover {
          & > img {
            border: 1px solid $commonBlue;
            opacity: 1;
          }
        }
      }
      &--active {
        & > a {
          & > img {
            border: 1px solid $commonBlue;
            opacity: 1;
          }
        }
      }
    }
  }
}
