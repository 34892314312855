.dojoIndex {
  .contentList__item {
    position: relative;
    h2 {
      margin-right: 30px !important;
    }
    .centerOnMap {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
    & > .imageWrapper {
      a {
        width: 70px;
      }
    }
    .contentDescription {
      text-indent: 0;
      font-size: 12px;
      text-align: left;
      & > p {
        & > span {
          display: inline-block;
          width: 95px;
          color: #676767;
        }
      }
    }
  }
}

@media only screen and (min-width: $mobilePortraitMinWidth) {
  .dojoIndex {
    .middleContainer {
      &__content {
        padding-bottom: 5px !important;
      }
    }
  }
}
